export default function() {
  return [
    {
      component: 'Section',
      props: {
        class: '',
        width: 'full',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'residential-page__text residential-page__header',
            },
            data: {
              content: this.$path('item.description'),
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      props: {
        class: 'residential-page__floor-plans',
        width: 'full',
      },
      blocks: {
        default: (this.$path('item.customFields.floor-plans') || []).map((item, index) => {
          return {
            component: 'Flex',
            props: {
              class: 'residential-page__masonry-floor-plan-layout',
              gap: '0rem',
            },
            blocks: {
              default: [
                {
                  component: 'Gallery',
                  props: {
                    columns: 1,
                    class: 'residential-page__masonry',
                  },
                  data: {
                    images: [item.image],
                  },
                },

                {
                  component: 'Text',
                  props: {
                    class: 'residential-page__floor-plan-text',
                  },
                  data: {
                    content: item.content,
                  },
                },
              ],
            },
          }
        }),
      },
    },
    {
      component: 'Section',
      props: {
        class: '',
        width: 'full',
      },
      blocks: {
        default: (this.$path('item.customFields.section') || [])
          .map((item, index) => {
            return [
              {
                component: 'Text',
                props: {
                  class: 'standard-post-text',
                },
                data: {
                  content: item.content,
                },
              },
              {
                component: 'Image',
                props: {
                  class: '',
                },
                data: {
                  image: item.image,
                },
              },
            ]
          })
          .flat(),
      },
    },
    {
      component: 'Section',
      props: {
        width: 'full',
        class: '',
      },
      blocks: {
        default: {
          component: 'Text',
          props: {
            class: 'go-back-button',
          },
          data: {
            content: this.$path('item.customFields.content'),
          },
        },
      },
    },

    {
      component: 'Banner',
      props: {
        class: 'call-to-action',
      },
      data: {
        backgroundImage: this.$path('item.customFields.call-to-action.image.url'),
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: this.$path('item.customFields.call-to-action.content'),
            },
            props: {
              class: 'call-to-action__text',
            },
          },
          {
            component: 'Image',
            data: {
              image: this.$path('item.customFields.call-to-action.image'),
            },
            props: {
              class: 'call-to-action__image',
            },
          },
        ],
      },
    },
  ]
}
