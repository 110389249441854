<template>
  <Loader :value="promises.fetch" class="residential-page">
    <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ResidentialComponents from '@/components/Residential'
import Residentials from '@/components/Residentials'
import ScrollAnchor from '@kvass/scroll-anchor'
import Shop from '@kvass/shop'
import BrowserApiMixin from '@/mixins/browser-api'
import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/residential.js'

import Masonry from '@kvass/template-gallery/Types/Masonry'

export default {
  mixins: [BrowserApiMixin('residential')],
  props: {
    id: String,
  },
  watch: {
    id: {
      handler(val) {
        return this.fetch(val).then(data => {
          if (!data) return this.$router.push({ name: 'notfound' })
        })
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('Residential', ['promises', 'item']),

    config() {
      return config.call(this)
    },
    customComponents() {
      return {
        ScrollAnchor,
        ...ResidentialComponents,

        Masonry,
      }
    },
  },
  methods: {
    ...mapActions('Residential', ['fetch']),
    gotoLead() {
      document.querySelector('#lead').scrollIntoView({
        behavior: 'smooth',
      })
    },
  },
  metaInfo() {
    return {
      title: this.item.name || `${this.$t('loading', { resource: null })}...`,
    }
  },
  components: {
    ...ResidentialComponents,
    Residentials,
    Shop,
    ScrollAnchor,
    Blocks,

    Masonry,
  },
}
</script>

<style lang="scss">
.residential-page {
  --section-padding-inline: 0rem;
  background-color: var(--background-color);

  &__header {
    @include respond-above('desktop') {
      margin-top: 5%;
    }
  }

  &__text {
    padding: 1.5rem 2.5rem;

    font-size: 1.3rem;
    max-width: 80%;

    @include respond-above('phone') {
      padding: 1rem 4rem 0rem 4rem;
    }

    h2 {
      font-size: 45px;
      margin-top: 1.5rem;
      margin-bottom: 0.75rem;
    }
    p {
      margin: 0 0 1.5rem;
    }
  }

  &__masonry {
    width: 70%;
    @include respond-below('phone') {
      width: 100%;
    }
    // reset
    padding: 0;
    padding-top: 50px;

    .kpb-gallery__item-thumbnail {
      object-fit: contain;
    }

    .kvass-media-render-image__image {
    }
    .masonry__content {
      grid-template-columns: 1fr !important;
    }
    .masonry__item {
      padding: 6rem;
    }
  }
  &__floor-plans {
  }

  &__floor-plan-layout {
    justify-content: center;
    align-content: center;
    align-items: center;
    @include respond-below('phone') {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__floor-plan-text {
    max-width: 100%;
    @include respond-above('phone') {
      max-width: 30%;
      align-self: center;
    }

    padding: 2rem 1.5rem;
    h2 {
      font-size: 1.4rem;
      margin-top: 1.5rem;
      margin-bottom: 0.75rem;
      font-weight: 400;
      @media (min-width: 544px) {
        font-size: 1.6rem;
      }
      @include respond-above('phone') {
        font-size: 2rem;
      }
    }
    p {
      font-size: 0.875rem;
      @media (min-width: 544px) {
        font-size: 1rem;
      }
      @include respond-above('phone') {
        font-size: 1.25rem;
      }
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
    }
    ul {
      li::marker {
        color: var(--secondary);
      }
      p {
        font-size: 1.1rem;
      }
    }
  }

  .go-back-button {
    padding: 5%;
    text-align: center;
    a {
      color: var(--orange-primary);
      border: 1px solid;
      box-shadow: none;
      background-color: inherit;
      font-weight: 400;
      &:hover {
        color: var(--orange-hover);
      }
      &:after {
        display: none;
      }
    }
  }

  .standard-post-text {
    padding: 4rem;
    font-size: 1.3rem;
    max-width: 80%;

    h2 {
      font-size: 45px;

      margin-top: 1.5rem;
      margin-bottom: 0.75rem;
    }
    p {
      margin: 0 0 1.5rem;
    }
  }

  .call-to-action {
    background-color: var(--secondary);
    color: white;
    background-size: contain;

    background-position: right center;

    @include respond-below('phone') {
      background-image: none !important;
    }

    &__text {
      padding: 1rem 2.5rem;
      @include respond-above('phone') {
        margin-left: 5%;
      }

      @include respond-above('desktop') {
        padding-top: 2rem;
        padding-bottom: 3rem;
        margin-left: 10%;
      }

      h2 {
        max-width: 30rem;

        margin-top: 1.5rem;
        margin-bottom: 0.75rem;

        font-size: 1.4rem;
        @media (min-width: 544px) {
          font-size: 1.6rem;
        }
        @include respond-above('phone') {
          font-size: 45px;
        }
      }

      p {
        max-width: 30rem;
        a[data-cta='primary'] {
          padding: 0.6rem;
          font-weight: 400;
          margin-top: 1rem;
        }
      }
    }
    &__image {
      @include respond-above('phone') {
        display: none;
      }

      width: 100%;
      height: auto;
      min-height: 180px;

      .kpb-image__image {
        object-fit: cover;
        object-position: right top;
        background-color: inherit;
      }
    }
  }
}
</style>
